export const deserialize = (cookieStr) => {
    const result = {};

    const decoded = decodeURIComponent(cookieStr);
    decoded
        .split(',')
        .map((t) => t.trim())
        .filter((t) => t != '')
        .forEach((t) => {
            const parts = t.split(':');
            if(parts.length == 2) {
                result[parts[0]]= parts[1];
            } else {
                throw new Error('malformed puc-up cookie', cookieStr);
            }
        });
    return result;
};

export const deserializeCF = (cfEncodedContext) => {
    const cfContext = JSON.parse(decodeURIComponent(cfEncodedContext));
    return cfContext.userProfile ? (cfContext.userProfile.v1 ? cfContext.userProfile.v1 : {}) : {};
};
