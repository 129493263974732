import Cookies from 'es-cookie';
import { deserialize, deserializeCF } from './serializer';
const COOKIE_NAME = 'puc-up';
const CF_COOKIE_NAME = 'userContext';

export const getTrait = (traitName) => {
    try {
        const traits = getAndParseTraits();
        return traits[traitName]
    } catch (e) {
        console.warn('up-reader in getTrait failed.', e);
    }
};

export const getAllTraits = () => {
    try {
        const traits = getAndParseTraits();
        return traits;
    } catch (e) {
        console.warn('up-reader in getAllTraits failed.', e);
        return {};
    }
};

const getAndParseTraits = () => {
    const cfCookie = Cookies.get(CF_COOKIE_NAME);
    if (!cfCookie) {
        const rawCookie = Cookies.get(COOKIE_NAME);
        if (!rawCookie) {
            console.warn('up-reader: Context cookie are missing. If you are developing locally check the readme;)')
            return {};
        }

        return deserialize(rawCookie);
    }
    return deserializeCF(cfCookie);
};

export function isAvailable() {
    try {
        return !!Cookies.get(CF_COOKIE_NAME) || !!Cookies.get(COOKIE_NAME);
    } catch (e) {
        console.warn('up-reader in isAvailable failed.', e);
        return false;
    }
}

export default {
    isAvailable,
    getTrait,
    getAllTraits
};
